<template>
  <div class="contact">
    <div class="contact_us" v-if="contact_info">
      <h3 style="margin-bottom: .3rem;">Contact us:</h3>
      <ul class="contact_container">
        <li>{{contact_info.office_address}}</li>
        <li v-for="(item, index) in contact_info.contact_info" :key="index">
          <span v-if="index<2">{{ item.content }} {{ item.note }}</span>
          <a v-else :href="`mailto:${item.note}`">{{ item.content }} {{ item.note }}</a>
        </li>
      </ul>
    </div>
    <div class="contact_us mt connect_my">
      <h3>CONNECT</h3>
      <ul class="Contact">
        <li v-for="(item, index) in contact_info.connect">
          <a @click="toDetailPage(item)">{{item.content}}</a>
        </li>
      </ul>
    </div>
    <div class="contact_us media" v-if="contact_info&&contact_info.media.length">
      <h3>Media:</h3>
      <div class="terrace" v-if="contact_info&&contact_info.media.length">
        <a :href="item.link" v-for="(item, index) in contact_info.media" :key="index">
          <div class="icon-img">
            <img :src="item.icon" alt="" />
          </div>
        </a>
      </div>
    </div>
    <div class="contact_us mt">
      <h3>DOWNLOAD JCI APP</h3>
      <div class="apps">
        <a :href="item.link" v-for="(item, index) in contact_info.download" :key="index">
          <div class="icon-img">
            <img :src="item.icon" alt="" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        contact_info: this.$store.state.configList.contact_info
      }
    },
    props: {
      type: {
        type: Number,
        default: 2
      }
    },
    components: {},
    mounted() {

    },
    created() {
    },

    methods: {
      toDetailPage(item) {
        // if (this.type == 1) {
        //   if (item.note.includes('senate')) {
        //     this.$router.push('/senate')
        //   } else if (item.note.includes('foundation')) {
        //     this.$router.push('/foundation')
        //   } else if (item.note.includes('alumni')) {
        //     this.$router.push('/alumni')
        //   }
        // } else {
        //   if (item.note.includes('senate')) {
        //     this.$router.replace('/senate')
        //   } else if (item.note.includes('foundation')) {
        //     this.$router.replace('/foundation')
        //   } else if (item.note.includes('alumni')) {
        //     this.$router.replace('/alumni')
        //   }
        // }
        window.open(item.note, '_self')
      }
    }
  }

</script>
<style lang="scss" scoped>
  .contact {
    background-color: #4266b0;
    text-align: left;
    color: #fff;
    padding: 0.5rem 0.3rem;

    >.mt {
      margin-top: 0.3rem;
    }

    .contact_container {
      li:nth-child(3) {
        margin-bottom: .5rem;
      }
    }

    >.contact_us {
      margin-bottom: .5rem;

      >h3 {
        font-size: 0.35rem;
      }

      >ul {
        >li {
          font-size: 0.24rem;
        }
      }

      a {
        color: #edbe38;
      }

      .apps {
        margin-top: .3rem;
        display: flex;

        img {
          width: 2.38rem;
          height: .7rem;
          margin-right: .3rem;
        }
      }
    }

    .connect_my {
      margin-top: .5rem;

      h3 {
        margin-bottom: .3rem;
      }

      li {
        display: flex;
        flex-direction: column;
      }
    }

    .media {
      >.terrace {
        display: flex;
        margin-top: 0.3rem;

        .icon-img {
          width: 0.7rem;
          height: 0.7rem;
          margin-right: 0.3rem;

          >img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
</style>