<template>
  <div class="lang_container">
    <div class="logo">
      <van-image :src="our_brand.logo" cover v-if="our_brand && our_brand.logo" @click="$router.push('/home')">
        <template v-slot:error>
          <img
            src="../../../assets/image/banner_2.png"
            alt=""
          />
        </template>
      </van-image>
    </div>
    <div class="language_box">
      <!-- <div class="language_content" v-if="langList.length">
        <img src="@/assets/image/banner_3.png" alt="" @click="show = !show" />
        <ul class="language" v-if="show && langList.length">
          <li
            :class="{ text_item: activeLi === index }"
            v-for="(item, index) in langList"
            @click="activeLi = index"
            :key="index"
          >
            {{ item.language }}
          </li>
        </ul>
      </div> -->
      <div class="user" @click="toDetail">
        <img src="@/assets/image/user.png" alt="" />
        <span>{{ user_count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      logo: "",
      langList: [],
      activeLi: "",
      user_count: 0,
      our_brand: this.$store.state.configList.our_brand,
    };
  },

  components: {},

  created() {
    this.getWebsiteLangList();
    this.getWebsiteIndexInfo();
  },

  methods: {
    // 获取语言
    getWebsiteLangList() {
      this.$http.getWebsiteLangList().then((res) => {
        if (res.status == 200) {
          this.langList = res.data.data;
        }
      });
    },

    // 获取注册人数
    getWebsiteIndexInfo() {
      this.$http.getWebsiteIndexInfo().then((res) => {
        if (res.status == 200) {
          this.user_count = res.data.data.user_count;
        }
      });
    },

    toDetail() {
      window.open("https://jci.cc/web/#/newsfeeds");
    },
  },
};
</script>
<style scoped lang="less">
.lang_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0.22rem;
  left: 0;
  padding: 0 0.3rem;

  .logo {
    width: 1.17rem;
    height: 0.39rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.language_box {
  display: flex;
  align-items: center;

  img {
    width: 0.46rem;
    height: 0.46rem;
  }

  .language_content {
    position: relative;
  }

  .language {
    position: absolute;
    width: 2rem;
    right: 0;
    top: 0.86rem;
    max-height: 3.2rem;
    background: #4266b0;
    border-radius: 0.2rem;
    padding-top: 0.3rem;
    padding-left: 0.3rem;
    overflow-y: auto;

    li {
      font-size: 0.26rem;
      color: #fff;
      margin-bottom: 0.24rem;
      text-align: left;
    }

    .text_item {
      color: red;
    }
  }

  .user {
    display: flex;
    justify-items: center;
    margin-left: 0.36rem;
    color: #fff;

    img {
      width: 0.4rem;
      height: 0.37rem;
    }
  }
}
</style>